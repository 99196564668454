import fetch from 'isomorphic-fetch';
import React, { Component } from 'react';
import {Prompt} from 'react-router';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Button, Spinner, Form } from 'react-bootstrap'
import TableComponent from './Components/TableComponent';
import cleaningColumns from './Components/Table/Columns/Cleaning';
import powerwashColumns from './Components/Table/Columns/PowerWash';
import AddCleaningJobModal from "./Components/AddCleaningJobModal";
import submitCleaningJob  from './helpers/Cleaning/cleaningJob';
import submitPowerwashingJob from './helpers/Cleaning/powerwashingJob';
import getTableSettings from './helpers/Cleaning/getTableSettings';
import {getCleaningCrews} from './helpers/Cleaning/cleaningCrew';
import { getActiveCleaningCommunities } from './helpers/Cleaning/cleaningCommunities';

export default withOktaAuth(class MessageList extends Component {

  constructor(props) {
    
    super(props)
    this.getAllCleaningJobData = this.getAllCleaningJobData.bind(this);
    this.getAllPowerwashingJobData = this.getAllPowerwashingJobData.bind(this);
    this.editCleaningData = this.editCleaningData.bind(this);
    this.deleteCleaningJobData = this.deleteCleaningJobData.bind(this);
    this.editPowerwashingData = this.editPowerwashingData.bind(this);
    this.deletePowerwashingJobData = this.deletePowerwashingJobData.bind(this);
    this.updateSaveButton = this.updateSaveButton.bind(this);
    this.updateRowSelected = this.updateRowSelected.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlockNavigation = this.handleBlockNavigation.bind(this);


    const { REACT_APP_SERVER_PORT } = process.env;
    this.serverPort = REACT_APP_SERVER_PORT
    
    this.state = {
      cleaningData: null,
      powerwashingData: null,
      userInfo: null,
      startDate: '',
      endDate: '',
      userGroups: null,
      showPowerwashing: false,
      showCleaning: false,
      powerwashingDataIsLoading: false,
      cleaningDataIsLoading: false,
      saveButtonDisabled: true,
      saveButtonDisabledLoading: false,
      shouldBlockNavigation: true,
      columnsToHide: ['true'],
      crewsData: [],
      communitiesData: null,
      showDeleteModal:false,
      confirmDelete:false,
      cleanDateToggleValue: false,
      cleanDateToggle: 'By Supply Pro Date',
    }

    this.userGroups = this.props.authState.accessToken.claims["Add-Groups"];

    this.adminGroup = "Admin";
    this.adminReadGroup = "Admin-Read";
    this.userGroup = "Users";
    this.adminCleanGroup = "Cleaning-Admin";

    this.readDataRequest = "read";
    this.writeDataRequest = "write";
    this.editDataRequest = "edit";
    this.deleteDataRequest = "delete";

    this.cleanNumberOptions = [
      { value: 'First Clean', label: 'First Clean' },
      { value: 'Second Clean', label: 'Second Clean' },
      { value: 'Third Clean', label: 'Third Clean' },
      { value: 'Fourth Clean', label: 'Fourth Clean' },
      { value: 'Reclean', label: 'Reclean' },
      { value: 'SPEC', label: 'SPEC' },
      { value: 'Lot Trash Pickup', label: 'Lot Trash Pickup' },
      { value: 'Service Charge', label: 'Service Charge'},
      { value: 'Trip Charge', label: 'Trip Charge' },
      { value: 'House Sweep', label: 'House Sweep' },
      { value: 'Warranty', label: 'Warranty'},
      { value: 'Moveout', label: 'Moveout'},
      { value: 'Stairwells', label: 'Stairwells'},
      { value: 'Residential', label: 'Residential'},
      { value: 'Model', label: 'Model'},
      { value: 'Carpet Cleaning', label: 'Carpet Cleaning'},
      { value: 'Inspections', label: 'Inspections'},
      { value: 'Adjustment', label: 'Adjustment'},
    ];

    this.powerWashOptions = [
      { value: 'Power Wash', label: 'Power Wash'},
      { value: 'Power Wash 2', label: 'Power Wash 2'},
      { value: 'Power Wash 3', label: 'Power Wash 3'},
      { value: 'Power Washing Touchup', label: 'Power Washing Touchup'},
    ];


    // this.state = {value: ''};
  }

  handleCleanDateToggle(string){
    this.setState({cleanDateToggle: string});
  }

  handleChangeStartDate(event) {
  this.setState({startDate: event.target.value});
}

handleCleanDateToggleValue(){
  if( this.state.cleanDateToggleValue === true){
    this.setState({cleanDateToggleValue: false});
    this.handleCleanDateToggle("By Supply Pro Date")
  } else{
    this.setState({cleanDateToggleValue: true});
    this.handleCleanDateToggle("By Actual Date")
  }

}

  handleChangeEndDate(event) {
  this.setState({endDate: event.target.value});
  }

  handleSubmit(event) {
    alert(this.state.startDate + ' ' + this.state.endDate);
    event.preventDefault();
  }

  handleBlockNavigation(shouldBlock){
    this.setState({shouldBlockNavigation: shouldBlock})
  }

  async componentDidMount() {
    this.setState({saveButtonDisabled: true});
    let userInfo = await this.props.oktaAuth.token.getUserInfo();
    this.setState({userInfo: userInfo});
    const tableName = 'cleaning_job_data';

    if(this.userGroups?.includes("Admin") ||
      this.userGroups?.includes(this.adminCleanGroup)){
        let accessToken = this.props.authState.accessToken.accessToken
        let columnsToHide = await getTableSettings(tableName, userInfo.name, accessToken) || [];
        let crewsData = await getCleaningCrews(accessToken) || [];
        let communitiesData = await getActiveCleaningCommunities(accessToken) || null;


      this.setState({ crewsData: crewsData});
      this.setState({communitiesData: communitiesData});
      this.setState({columnsToHide: columnsToHide});
    }
  }

  async getAllCleaningJobData(){
    if((this.state.endDate !== undefined) && (this.state.startDate !== undefined)){
      this.setState({showCleaning: false});
      this.setState({showPowerwashing: false});
      this.setState({cleaningDataIsLoading: true});
      try {
          // const { REACT_APP_SERVER_PORT } = process.env;
          // let serverPort = REACT_APP_SERVER_PORT
          
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });
          const { REACT_APP_SERVER_URL } = process.env;
          let getUrl;
          if(this.state.cleanDateToggleValue === false){
            getUrl = `${REACT_APP_SERVER_URL}/Cleaning`;
          } else{
            getUrl =`${REACT_APP_SERVER_URL}/Cleaning/actual`
          }

          const response = await fetch(getUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': 'read'
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }) 
          })

          let cleaningJobData = await response.json();


          for(let row of cleaningJobData){
            for (let value in row){
              if(row[value] === null){
                row[value] = '';
              }
            }
          }

          this.setState({ cleaningData: cleaningJobData });
          this.setState({showCleaning: true});
          this.setState({showPowerwashing: false});
          this.setState({cleaningDataIsLoading: false});

        } catch (err) {
          alert(err);
          this.setState({cleaningDataIsLoading: false});
          
        }
    } else{
      alert("Please Select a Start and End Date");
    }
  }

  async getAllPowerwashingJobData(){
    if((this.state.endDate !== undefined) && (this.state.startDate !== undefined)){
      this.setState({showPowerwashing: false});
      this.setState({showCleaning: false});
      this.setState({powerwashingDataIsLoading: true});
      try {
          // const { REACT_APP_SERVER_PORT } = process.env;
          // let serverPort = REACT_APP_SERVER_PORT
          
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });
          const { REACT_APP_SERVER_URL } = process.env;
          let getUrl;

          if(this.state.cleanDateToggleValue === false){
            console.log("SUPPLY PRO!");
            getUrl = `${REACT_APP_SERVER_URL}/Powerwashing/`;
          } else{
            console.log("ACTUAL");
            getUrl =`${REACT_APP_SERVER_URL}/Powerwashing/actual`
          }

          const response = await fetch(getUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': 'read'
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }) 
          })

          let powerWashingJobData = await response.json();

          for(let row of powerWashingJobData){
            for (let value in row){
              if(row[value] === null){
                row[value] = '';
              }
            }
          }

          this.setState({powerwashingData: powerWashingJobData });
          this.setState({showPowerwashing: true});
          this.setState({powerwashingDataIsLoading: false});

        } catch (err) {
          alert(err);
          this.setState({powerwashingDataIsLoading: false});
          
        }
    } else{
      alert("Please Select a Start and End Date");
    }

  }

  async editCleaningData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});

      for(let row of editData){
        for (let value in row){
          if(row[value] === ''){
            row[value] = null;
          }
        }
      }

      for(let row of editData){
        if(row['inspected_cost'] !== null){
          row['inspected_cost'] = row['inspected_cost'].replace('$', '');

        }
        if(row['job_cost'] !== null){
          row['job_cost'] = row['job_cost'].replace('$', '');
        }
        if(row['revenue'] !== null){
          row['revenue'] = row['revenue'].replace('$', '');
        }
      }

      const response = await fetch(`${REACT_APP_SERVER_URL}/Cleaning/editCleaningJob`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      else if(response.status === 200){
        alert('Data Has Updated');
        await this.getAllCleaningJobData();
      }
      else if(response.status === 409){
        alert(`${response.statusText}`);
      }
      else if(response.status > 401){
        alert(`error: ${response.statusText}`);
      }
      let test = await response.json();
      this.setState({ cleaningData: test });
      this.setState({saveButtonDisabledLoading: false});
    } catch (error) {
      // handle error as needed
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async editPowerwashingData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});

      for(let row of editData){
        for (let value in row){
          if(row[value] === ''){
            row[value] = null;
          }
        }
      }

      for(let row of editData){
        if(row['inspected_cost'] !== null){
          row['inspected_cost'] = row['inspected_cost'].replace('$', '');

        }
        if(row['job_cost'] !== null){
          row['job_cost'] = row['job_cost'].replace('$', '');
        }
        if(row['revenue'] !== null){
          row['revenue'] = row['revenue'].replace('$', '');
        }
      }

      const response = await fetch(`${REACT_APP_SERVER_URL}/Powerwashing/editPowerwashingJob`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      else if(response.status === 200){
        alert('Data Has Updated');
        await this.getAllPowerwashingJobData();
      }
      else if(response.status === 409){
        alert(`${response.statusText}`);
        // alert(`Data cannot be edited because Community "${editData.community}" lot:  "${editData.lotNumber}" Clean Number: "${editData.cleanNumber}" Already Exists`);
      }
      else if(response.status > 401){
        alert(`error: ${response.statusText}`);
      }
      let test = await response.json();
      this.setState({ cleaningData: test });
      this.setState({saveButtonDisabledLoading: false});
    } catch (error) {
      // handle error as needed
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }


  updateSaveButton(buttonState){
    if(this.userGroups.includes("Admin") || this.userGroups.includes("Cleaning-Admin") ){
      this.setState({saveButtonDisabled:buttonState});
    }

  }

  updateRowSelected(isRowSelected){
    this.setState({rowSelected:isRowSelected})
  }

  deleteJobData(rowsToDelete){
    if(this.showCleaning){
      this.deleteCleaningJobData(rowsToDelete);
    } 
    else if(this.showPowerwashing){
      this.deletePowerwashingJobData(rowsToDelete);
    }
  }

  async deleteCleaningJobData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT

        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ userInfo: userInfo });
        this.setState({saveButtonDisabledLoading: true});
        const response = await fetch(`${REACT_APP_SERVER_URL}/Cleaning/deleteCleaningJob`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
          body: JSON.stringify({
              // Validation data coming from a form usually
              editData: editData,
              'user': this.state.userInfo.name
          }) 
        })
        if(await response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }

        if( await response.status === 204){
          alert('Data was Deleted');
        }
        if(await response.status > 401){
          alert(`error: ${response.statusText}`);
        }
        this.setState({saveButtonDisabledLoading: false});
        await this.getAllCleaningJobData();

    } catch (error) {
      // handle error as needed
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async deletePowerwashingJobData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT


      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      const response = await fetch(`${REACT_APP_SERVER_URL}/Powerwashing/deletePowerwashingJob`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(await response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if( await response.status === 204){
        alert('Data was Deleted');
      }
      if(await response.status > 401){
        alert(`error: ${response.statusText}`);
      }
      this.setState({saveButtonDisabledLoading: false});
      await this.getAllPowerwashingJobData();
      

    } catch (error) {
      // handle error as needed
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  render() {
    let content;
    if(this.userGroups?.includes("Admin") ||
        this.userGroups?.includes(this.adminCleanGroup)){
      if ((typeof this.state.cleaningData !== 'undefined') && (this.state.showCleaning)){ 
          content = 
          <TableComponent 
            tableColumns = {cleaningColumns(this.state.crewsData, this.state.communitiesData)}
            tableData = {this.state.cleaningData}
            saveButtonDisabled={this.state.saveButtonDisabled}
            saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
            rowSelected={this.state.rowSelected}
            editData={this.editCleaningData}
            deleteData={this.deleteCleaningJobData}
            updateSaveButton={this.updateSaveButton}
            columnsToHide={this.state.columnsToHide}
            showLegend={false}
            showDeleteModal={this.handleShowDeleteModal}
            confirmDelete={this.state.confirmDelete}
            cumulativeRow={true}
            printFilename={`Cleaning_Report_${this.state.startDate}_${this.state.endDate}`}
          />;
      } 
      else if((typeof this.state.powerwashingData !== 'undefined') && (this.state.showPowerwashing)){
        content =        
        <TableComponent 
          tableColumns = {powerwashColumns(this.state.crewsData, this.state.communitiesData)}
          tableData = {this.state.powerwashingData}
          saveButtonDisabled={this.state.saveButtonDisabled}
          saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
          rowSelected={this.state.rowSelected}
          editData={this.editPowerwashingData}
          deleteData={this.deletePowerwashingJobData}
          updateSaveButton={this.updateSaveButton}
          columnsToHide={this.state.columnsToHide}
          showLegend={false}
          showDeleteModal={this.handleShowDeleteModal}
          cumulativeRow={true}
          printFilename={`Powerwashing_Report_${this.state.startDate}_${this.state.endDate}`}
        />;
      }
      else{
          //content = <p>Select Dates Above</p>
      }
      return <div>
        <h1 data-cy={"cleaning-reports-title"}>Cleaning Reports </h1>
        <Prompt
          when={!this.state.saveButtonDisabled}
          message='Are you sure you want to leave the page? You may have unsaved changes'
        />

        <br/>
        { (this.state.communitiesData !== null && this.state.communitiesData !== undefined) && 
        <div>
        <AddCleaningJobModal
          onHide={this.showAddCustomerModel}
          submitCustomerTrackingData = {this.submitCustomerTrackingData}
          updateCustomerName = {this.updateCustomerName}
          getCustomerNames = {this.getCustomerNames}
          show={this.state.showAddCustomerModel}
          userGroups={this.userGroups}
          userInfo={this.state.userInfo}
          customerName = {this.state.customerName}
          customerData = {this.state.customerData}
          submitButtonDisabled = {this.state.submitButtonDisabled}
          buttonName = {"Add Cleaning Job"}
          variant = {"success"}
          submitCleaningJob = {submitCleaningJob}
          cleanType = {"Cleaning"}
          communityOptions = {this.state.communitiesData}
          crewsData = {this.state.crewsData}
          testPropName = {"add-cleaning-job-button"}
          testModalType={'clean-job'}
          cleanOptions = {this.cleanNumberOptions}
        />

        <br/>
          <AddCleaningJobModal
            onHide={this.showAddCustomerModel}
            submitCustomerTrackingData = {this.submitCustomerTrackingData}
            updateCustomerName = {this.updateCustomerName}
            getCustomerNames = {this.getCustomerNames}
            show={this.state.showAddCustomerModel}
            userGroups={this.userGroups}
            userInfo={this.state.userInfo}
            customerName = {this.state.customerName}
            customerData = {this.state.customerData}
            submitButtonDisabled = {this.state.submitButtonDisabled}
            buttonName = {"Add Powerwashing Job"}
            variant = {"secondary"}
            submitCleaningJob = {submitPowerwashingJob}
            cleanType =  {"Power Washing"}
            communityOptions = {this.state.communitiesData}
            crewsData = {this.state.crewsData}
            testPropName = {"add-powerwash-job-button"}
            testModalType={'powerwashing'}
            cleanOptions={this.powerWashOptions}
          />
        <br/>
        </div>
    }

            <form onSubmit={this.handleSubmit}>
              <label style = {{ "marginBottom": "20px", "fontWeight": "bold"}}>
              Start Date:
              </label>
              <input 
                data-cy={"cleaning-start-date"}
                type="date" 
                startdate={this.state.startDate} 
                onChange={this.handleChangeStartDate}
                style = {{"marginRight": "20px"}}
              />
              <label style = {{ "fontWeight": "bold"}}>
              End Date:
              </label>
              <input 
                data-cy={"cleaning-end-date"}
                type="date" 
                enddate={this.state.endDate} 
                onChange={this.handleChangeEndDate}
              />
          </form>
          <Form.Switch
            data-cy="toggle-get-type"
            label={this.state.cleanDateToggle}
            onChange={()=>{
              this.handleCleanDateToggleValue();
            }}
            value={this.state.cleanDateToggleValue}
            />
            <br/>
          { (this.userGroups.includes(this.adminGroup) 
              || this.userGroups.includes(this.adminReadGroup)
              ||  this.userGroups.includes(this.adminCleanGroup)) &&
          <Button 
            data-cy="get-cleaning-data-button"
            variant="success" 
            onClick={this.getAllCleaningJobData}  
            style = {{"marginRight": "20px", "marginBottom": "20px"}}
            disabled={this.state.cleaningDataIsLoading}
            >
            {
              this.state.cleaningDataIsLoading ? 
              <div>Loading 
                <Spinner animation="border" size="sm" ></Spinner>
              </div> : 
              'Get Cleaning Data'
            }
          </Button>
          }
          { (this.userGroups.includes(this.adminGroup) || 
                this.userGroups.includes(this.adminReadGroup) ||
                this.userGroups.includes(this.adminCleanGroup)) &&
          <Button 
            data-cy="get-powerwashing-data-button"
            variant="secondary"  
            onClick={this.getAllPowerwashingJobData} 
            style = {{"marginRight": "20px", "marginBottom": "20px"}}
            disabled={this.state.powerwashingDataIsLoading}
          >
          
          {
            this.state.powerwashingDataIsLoading ? 
            <div>Loading 
              <Spinner animation="border" size="sm" ></Spinner>
            </div> : 
            'Get Powerwashing Data'
          }
          </Button>
          
          } 
          {content}
      </div>  
    } else{
      return <div>
              <h1>This Page doesn't exist</h1> 
              <h3>Click home or use the back arrows to go to the previous page</h3>
            </div>
    }
  }
});